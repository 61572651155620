<script setup lang="ts">
const filteredChildren = ref([])

const childToRemove = {
  'croisieres-fluviales': [
    'fluviale-elbe',
    'fluviale-garonne-dordogne',
    'fluviale-gironde',
    'fluviale-guadania-guadalquivir',
    'fluviale-irrawaddy-birmanie',
    'fluviale-le-po-et-italie',
    'fluviale-rhin-main-moselle',
    'fluviale-rhone-saone',
    'fluviale-seine',
    'fluviale-volga-russie',
    'fluviale-yang-tse-kiang-chine',
    'fluviale-brahmapoutre-inde',
  ],
}

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  },
  children: {
    type: Object,
    required: true,
  },
  image: {
    type: Object,
    required: true,
  },
})

onMounted(() => {
  filteredChildren.value = props.children

  if (childToRemove[props.slug]) {
    filteredChildren.value = props.children.filter(
      child => !childToRemove[props.slug].includes(child.slug),
    )
  }
})
</script>

<template>
  <div class="home-destination-slide">
    <NuxtImg
      class="home-destination-slide--image"
      loading="lazy"
      format="webp"
      width="1000"
      :src="image.url"
      :alt="image.alt"
    />

    <div class="home-destination-slide--content">
      <div class="home-destination-slide--over-title">
        {{ $t('home.cruise.choose') }}
      </div>
      <div class="home-destination-slide--title">
        {{ title }}
      </div>

      <ul class="home-destination-slide--sub-destinations">
        <li
          v-for="(child, index) in filteredChildren"
          :key="index"
        >
          <UiElementsButton
            v-if="child.displayOnFront"
            variant="dark"
            size="small"
            color-style="link"
            :to="{ name: 'destinations-slug', params: { slug: child.slug } }"
          >
            {{ child.name }}
          </UiElementsButton>
        </li>
      </ul>

      <div class="actions">
        <UiElementsButton
          variant="dark"
          color-style="flat"
          size="medium"
          :to="{ name: 'destinations-slug', params: { slug: slug } }"
        >
          {{ $t('home.cruise.discover') }}
        </UiElementsButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.home-destination-slide {
  width: 100%;
  position: relative;
  color: white;

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding: 0 .5em;
    font-size: 1.25rem;
  }

  &--image {
    width: 100%;
    height: 90vh;
    filter: brightness(60%);
    object-fit: cover;

    @media (min-width: map-get($grid-breakpoints, md)) {
      height: 70vh;
    }
  }

  &--content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    gap: 1.25em;

    @media (min-width: map-get($grid-breakpoints, md)) {
      justify-content: center;
    }
  }

  &--over-title {
    font-family: var(--font-secondary);
    font-style: italic;
    margin-top: 28%;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 0;
      height: auto;
    }
  }

  &--title {
    font-family: var(--font-secondary);
    font-size: 2.3em;
    margin-bottom: 3.5em;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 0;
      font-size: 3em;
    }
  }

  &--sub-destinations {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap : 1em;

    .button {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.875rem;
    }
  }
}
</style>
