<script setup lang="ts">
const config = useRuntimeConfig()

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  provider: {
    type: String,
    default: 'default',
  },
  controls: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  playsinline: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: String,
    default: 'metadata',
  },
  poster: {
    type: String,
    default: '',
  },
  videoClass: {
    type: String,
    default: '',
  },
})

const videoSrc = ref('')

onMounted(() => {
  switch (props.provider) {
    case 'strapi':
      videoSrc.value = config.public.strapi.url + props.src
      break
    default:
      videoSrc.value = props.src
  }
})
</script>

<template>
  <video
    v-if="videoSrc"
    ref="videoElement"
    :src="videoSrc"
    :controls="controls"
    controlsList="nofullscreen"
    playsinline
    :autoplay="autoplay"
    :muted="muted"
    :loop="loop"
    :preload="preload"
    :poster="poster"
    :playsinline="playsinline"
    :class="videoClass"
  >
    Your browser does not support the video tag.
  </video>
</template>
